import StorageUtils from '@/utils/storage-utils.js'

var AuthUtils = {
    // 检验登录是否有效
    isValidLogin() {
        // JSON.parse stringify
        var token_time = StorageUtils.get('token_time')
        // console.log(token_time)
        if (token_time == undefined) {
            this.userLogout(false)
            return false
        }

        var tokenTime = parseInt(token_time / 1000)
        // console.log(tokenTime)
        var nowTime = parseInt((new Date()).getTime() / 1000)
        // console.log(nowTime)
        var spe = nowTime - tokenTime
        // console.log(spe)
        
        // 超过1小时（3600s）后失效
        if (spe > 3600) {
            this.userLogout(false)
            return false
        } else {
            var token = StorageUtils.get('token')
            if (token == 'undefined' || token == null) {
                this.userLogout(false)
                return false
            }
    
            // 刷新登录时间
            // this.refreshTokenTime()
        }
        return true
    },
    // 登录操作
    userLogin(data) {
        StorageUtils.set('token', data.token)
        StorageUtils.set('user_id', data.user_id)
        StorageUtils.set('user_type', data.user_type)
        StorageUtils.set('ref_id', data.ref_id)
        StorageUtils.set('name', data.name)
        StorageUtils.set('is_super', data.is_super)
        StorageUtils.set('sys_permissions', data.sys_permissions)

        // 新登录的记录当前商户id
        if (StorageUtils.get('merchant_id', undefined) == undefined) {
            // 记录当前商户
            StorageUtils.set('merchant_id', data.ref_id)
        }

        // 刷新登录时间
        this.refreshTokenTime()
    },
    // 登出操作
    userLogout(allClean = true) {
        StorageUtils.remove('token')
        StorageUtils.remove('user_id')
        StorageUtils.remove('user_type')
        StorageUtils.remove('ref_id')
        StorageUtils.remove('name')
        StorageUtils.remove('is_super')
        StorageUtils.remove('sys_permissions')

        StorageUtils.remove('token_time')
        
        if (allClean) {
            StorageUtils.remove('merchant_id')
            StorageUtils.remove('shop_id')
            StorageUtils.remove('shop_name')
            StorageUtils.remove('source_code')
        } 
    },
    // 刷新登录时间
    refreshTokenTime() {
        let timestamp = (new Date()).getTime();
        StorageUtils.set('token_time', timestamp)
    },
    // 判断是否有某项权限
    hasPermission: function(code) {
        var sys_permissions = StorageUtils.get('sys_permissions', [])
        // console.log(sys_permissions)
        var result = sys_permissions.find(function(value, index, arr) {
            return value == code;
        })
        return (typeof(result) !== 'undefined') ? true : false
    },
    // 检验当前shop_id合法性
    checkShopId() {
        if (this.getCurrentShopId() == undefined) {
            return false
        } else if(StorageUtils.get('ref_id') != StorageUtils.get('merchant_id')) {
            StorageUtils.remove('merchant_id')
            StorageUtils.remove('shop_id')
            StorageUtils.remove('shop_name')
            StorageUtils.remove('source_code')
            return false
        }
        return true
    },
    // 刷新当前商户id为登录用户的商户id
    resetMerchantId() {
        StorageUtils.set('merchant_id', this.getUserId())
    },

    // ===================================================================================================
    // 属性方法
    // ===================================================================================================

    getToken() {
        return StorageUtils.get('token', undefined)
    },
    getUserId() {
        return StorageUtils.get('ref_id', undefined)
    },
    getUserName() {
        return StorageUtils.get('name', undefined)
    },
    setCurrentShopId(shop_id) {
        return StorageUtils.set('shop_id', shop_id)
    },
    getCurrentShopId() {
        return StorageUtils.get('shop_id', undefined)
    },
    setCurrentShopName(shop_name) {
        return StorageUtils.set('shop_name', shop_name)
    },
    getCurrentShopName() {
        return StorageUtils.get('shop_name', undefined)
    },
    setCurrentMerchantpId(merchant_id) {
        return StorageUtils.set('merchant_id', merchant_id)
    },
    getCurrentMerchantpId() {
        return StorageUtils.get('merchant_id', undefined)
    },
    setCurrentShopSourceCode(source_code) {
        return StorageUtils.set('source_code', source_code)
    },
    getCurrentShopSourceCode() {
        return StorageUtils.get('source_code', undefined)
    },
};

export default AuthUtils