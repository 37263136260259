import GlobalConfig from '@/config/index.js'
import Utils from '@/utils/index.js'
// import auth from '@/utils/auth-utils.js'
import { registAxios } from '@/utils/request-utils.js'
import AuthUtils from '@/utils/auth-utils.js';
import Emitter from 'tiny-emitter'


export function registApp(app) {

  /*
  app.config.errorHandler = (err, vm, info) => {
    // 处理错误
    // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
    console.log(err)
    console.log(vm)
    console.log(info)
  }
  */



    // console.log(registAxios)
    registAxios(app)


    app.config.globalProperties.$$config = GlobalConfig;
    app.config.globalProperties.$$utils = Utils;
    app.config.globalProperties.$$auth = AuthUtils;


    const emitter = new Emitter()
    app.config.globalProperties.$$emitter = emitter


    app.use({
        install (Vue, options) {
          /*
          Vue.mixin(mixins)
      
          // 注册第三方库
          _.each(libs, (item, key) => {
            Vue.prototype['$$' + key] = item
          })
      
          // 注册全局方法，如常用的接口方法，工具方法等。
          _.each(plugins, (item, key) => {
            Vue.prototype['$$' + key] = item
          })
          */
        //   vueApp.$
        //  console.log(vueApp)
        //   vueApp.$$config = 123;
        //   Vue.prototype['$$utils'] = utils;
    
        //   Vue.prototype['$$api'] = utils.api;
    
        //   Vue.prototype['$$auth'] = auth;
    
        //   Vue.prototype['$$data'] = data;
        //   
          // Vue.prototype.API = api;
    
        //   Vue.prototype.$window = window;
        }
      })
}
