import StorageUtils from './storage-utils.js'
import Datetime from './datetime-utils.js'

var Utils = {
  // storage: StorageUtils,
  // datetime: Datetime,


  /**
   * 对象拷贝
   * @param {*} from 源对象
   * @param {*} fields 字段
   */
  cloneObject(from, fields = []) {
    var to = {}
    fields.forEach((value, index, array) => {
      if (typeof(value) === 'object') {
        let key = value.key || undefined
        let defaultValue = (value.default !== undefined) ? value.default : undefined

        if (key !== undefined) {
          to[key] = from[key]

          if (to[key] === undefined && defaultValue !== undefined) {
            to[key] = defaultValue
          }
        }
      } else if (typeof(value) === 'string') {
        to[value] = from[value]
      }
    });
    return to
  },
  /**
   * 转换成日期时间文本格式
   * @param {*} number 
   * @returns 
   */
  toDataTime(inValue){
    var date = new Date(inValue * 1000)
    var Y = date.getFullYear()
    var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1)
    var D = (date.getDate() < 10 ? "0" + (date.getDate()) : date.getDate())
    var H = (date.getHours() < 10 ? "0" + (date.getHours()) : date.getHours())
    var F = (date.getMinutes() < 10 ? "0" + (date.getMinutes()) : date.getMinutes())
    var S = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds())
    return `${Y}-${M}-${D} ${H}:${F}:${S}`
  },
  toDataTimeHtml(inValue){
    var date = new Date(inValue * 1000)
    var Y = date.getFullYear()
    var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1)
    var D = (date.getDate() < 10 ? "0" + (date.getDate()) : date.getDate())
    var H = (date.getHours() < 10 ? "0" + (date.getHours()) : date.getHours())
    var F = (date.getMinutes() < 10 ? "0" + (date.getMinutes()) : date.getMinutes())
    var S = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds())
    return `<div>${Y}-${M}-${D}</div><div>${H}:${F}:${S}</div>`
  },
  toDate(inValue){
    var date = new Date(inValue * 1000)
    var Y = date.getFullYear()
    var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1)
    var D = (date.getDate() < 10 ? "0" + (date.getDate()) : date.getDate())
    return `${Y}-${M}-${D}`
  },
  toFixed2(inValue){
    return Math.floor(inValue * 100) / 100
  },
  toFixed2_2(inValue){
    let num = 0; num = parseFloat(inValue).toFixed(2);
    if (parseFloat(num) === 0 && isNaN(num)) { num = "0.00"; }
    return num
  },
}

export default Utils
