
// 订单-跨境订单管理-订单列表
const v1_trade_order_list_OrderListPage = () => import(/* webpackChunkName: "trade" */ '@/pages/v1/trade/order/list/OrderListPage')
// 订单抓取
// const trade_catch_OrderCatchPage = () => import(/* webpackChunkName: "trade" */ '@/pages/trade/catch/OrderCatchPage')



// 订单-平台订单管理-平台订单列表
const v1_trade_platform_order_list_ShopListPage = () => import(/* webpackChunkName: "trade" */ '@/pages/v1/trade/platform_order/list/ShopListPage')

// 订单-平台订单管理-微盟订单列表
const v1_trade_weimob_shop_OrderListPage = () => import(/* webpackChunkName: "trade" */ '@/pages/v1/trade/weimob/shop/OrderListPage')

// 订单-平台订单管理-微盟订单详情
const v1_trade_weimob_shop_OrderDetailPage = () => import(/* webpackChunkName: "trade" */ '@/pages/v1/trade/weimob/shop/OrderDetailPage')

// -----------------------------------------------------------------------------------------------------



export const tradeRoute = [
  // 订单-跨境订单管理-订单列表
  { path: '/v1/trade/order/list', component: v1_trade_order_list_OrderListPage, meta: { asideNavIndex: '/v1/trade/order/list', asideNavOpeneds: '/v1/trade' } },


  // 订单-平台订单管理-平台订单列表
  { path: '/v1/trade/platform-order/shop', component: v1_trade_platform_order_list_ShopListPage, meta: { asideNavIndex: '/v1/trade/platform-order/shop' } },

  // 订单-平台订单管理-微盟平台订单列表
  { path: '/v1/trade/weimob/shop/:id/order/list', name: 'WeimobOrderListPage', component: v1_trade_weimob_shop_OrderListPage, meta: { asideNavIndex: '/v1/trade/platform-order/shop' } },
  
  // 订单-平台订单管理-微盟订单详情
  { path: '/v1/trade/weimob/shop/:id/order/:id2/detail', name: 'WeimobOrderDetailPage', component: v1_trade_weimob_shop_OrderDetailPage, meta: { asideNavIndex: '/v1/trade/platform-order/shop' } },
  // 订单-平台订单管理-微盟订单搜索
  { path: '/v1/trade/weimob/shop/:id/order/detail', name: 'WeimobOrderDetailPage2', component: v1_trade_weimob_shop_OrderDetailPage, meta: { asideNavIndex: '/v1/trade/platform-order/shop' } },
]




