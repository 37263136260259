import axios from 'axios'
import VueAxios from 'vue-axios'
import GlobalConfig from '@/config/index.js'
import { ElMessage } from 'element-plus'
import StorageUtils from '@/utils/storage-utils.js'


export function registAxios(app) {
    
    axios.defaults.baseURL = GlobalConfig.host
    axios.defaults.timeout = 60000
    // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
    // axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.defaults.validateStatus = (status) => {
        return status < 600; // 状态码在大于或等于500时才会 reject
    }
    
    // console.log(axios.defaults)
    app.use(VueAxios, axios)

    // app.provide('$$http', axios)
}


export function request(inConfig) {
    // console.log(inConfig)
    var axiosRequest = axios.create();
    // console.log(axiosRequest)
    
    axiosRequest.interceptors.request.use(
        (config) => {
            if (config.token) {
                config.headers.Authorization = 'Bearer ' + StorageUtils.get('token', undefined);
            }
            if (config.commonApi) {
                config.headers['x-request-token'] = '50f92b9ddec0e275a191997a790fdf96'
                config.headers['x-timestamp'] = 1413388800
            }
            // console.log(config)
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    
    
    axiosRequest.interceptors.response.use(
        (response) => {
            // console.log('OK')
            // console.log(response)
            return response;
        }, 
        (error) => {
            // console.log('Error')
            console.log(error)
            // console.log(error.response.status)
            // if (error.response.data.message) {
            //     ElMessage({
            //         message: error.response.data.message,
            //         type: 'error',
            //         duration: 3000
            //     })
            // } else 

            /*
            ElMessage({
                message: 'Error !!!',
                type: 'error',
                duration: 3000
            })
            
            if (error.response.status === 404) {
                ElMessage({
                    message: '404 errorr!!!',
                    type: 'error',
                    duration: 3000
                })
            } else {
                ElMessage({
                    message: 'Error !!!',
                    type: 'error',
                    duration: 3000
                })
            }
            */
            return Promise.reject(error);
        }
    );
    
    // try {
        var requestResult = axiosRequest.request(inConfig).catch((error) => {
            ElMessage({
                message: '系统处理异常',
                type: 'error',
                duration: 3000
            })
        })
        return requestResult
    // }
    // catch(err) {
    //     console.log(err)
    // }
    // finally {
    // }
}