import { createStore } from 'vuex'

export default createStore({
  state: {
    info: 'hello',
    //
    currentPage: '',
    pageList: [],
    //
    nav1Index: 'index',
    nav2Index: '',
  },
  mutations: {
    // 定义mutations，用于修改状态(同步)
    updateInfo (state, payload) {
      state.info = payload
    }
  },
  actions: {
    // 定义actions，用于修改状态(异步)
    // 2秒后更新状态
    // updateInfo (context, payload) {
    //   setTimeout(() => {
    //     context.commit('updateInfo', payload)
    //   }, 2000)
    // }

    addPage(context, payload) {
      var newPageItem = payload.item
      if (context.state.pageList.some((item) => {return item.name == newPageItem.name}) == false) {
        context.state.pageList.push(payload.item)
      }
      context.state.currentPage = newPageItem.name
    },
    removePage(context, payload) {
      var pageName = payload.name
      var findIndex = context.state.pageList.findIndex((item) => {return item.name == pageName})
      console.log(findIndex)
      if (findIndex != -1) {
        if (pageName == context.state.currentPage) {
          context.state.pageList.splice(findIndex, 1)
          var newPageName = (context.state.pageList[context.state.pageList.length - 1]).name
          context.state.currentPage = newPageName
        } else {
          context.state.pageList.splice(findIndex, 1)
        }
      }
    },
    changePage(context, payload) {
      context.state.currentPage = payload.name
    },
    //
    setNav1Index(context, payload) {
      context.state.nav1Index = payload.index
    },
    setNav2Index(context, payload) {
      context.state.nav2Index = payload.index
    },
  },
  getters: {
    // 定义一个getters
    formatInfo (state) {
      return state.info + ' Tom'
    },
    getPages(state) {
      return state.pageList
    },
    getCurrentPage(state) {
      return state.currentPage
    },
    //
    getNav1Index(state) {
      return state.nav1Index
    },
    getNav2Index(state) {
      return state.nav2Index
    },
  },
  modules: {
  }
})