
// 申报 - 商户订单申报 - 订单申报
const v1_declaration_customs_list_OrderListPage = () => import(/* webpackChunkName: "declaration" */ '@/pages/v1/declaration/customs/list/OrderListPage')
// 申报 - 商户订单申报 - 支付申报
const v1_declaration_payment_list_OrderListPage = () => import(/* webpackChunkName: "declaration" */ '@/pages/v1/declaration/payment/list/OrderListPage')
// 申报 - 商户订单申报 - 物流申报
const v1_declaration_logistics_list_OrderListPage = () => import(/* webpackChunkName: "declaration" */ '@/pages/v1/declaration/logistics/list/OrderListPage')
// 申报 - 商户订单申报 - 清单申报
const v1_declaration_clearance_list_OrderListPage = () => import(/* webpackChunkName: "declaration" */ '@/pages/v1/declaration/clearance/list/OrderListPage')
// 申报 - 商户订单申报 - 仓储申报
const v1_declaration_warehouse_list_OrderListPage = () => import(/* webpackChunkName: "declaration" */ '@/pages/v1/declaration/warehouse/list/OrderListPage')

// 申报 - 推送管理 - 通知推送
const v1_declaration_push_notice_NoticePushPage = () => import(/* webpackChunkName: "declaration" */ '@/pages/v1/declaration/push/notice/NoticePushPage')

// 申报 - 订单信息推送 - 运单处理
const v1_declaration_logistics_list_WaybillListPage = () => import(/* webpackChunkName: "declaration" */ '@/pages/v1/declaration/logistics/list/WaybillListPage')


// -----------------------------------------------------------------------------------------------------



export const declarationRoute = [
  // 申报 - 商户订单申报 - 订单申报
  { path: '/v1/declaration/customs/list', component: v1_declaration_customs_list_OrderListPage, meta: { asideNavIndex: '/v1/declaration/customs/list', asideNavOpeneds: ['/v1/declaration'] } },
  // 申报 - 商户订单申报 - 支付申报
  { path: '/v1/declaration/payment/list', component: v1_declaration_payment_list_OrderListPage, meta: { asideNavIndex: '/v1/declaration/payment/list', asideNavOpeneds: ['/v1/declaration'] } },
  // 申报 - 商户订单申报 - 物流申报
  { path: '/v1/declaration/logistics/list', component: v1_declaration_logistics_list_OrderListPage, meta: { asideNavIndex: '/v1/declaration/logistics/list', asideNavOpeneds: ['/v1/declaration'] } },
  // 申报 - 商户订单申报 - 支付申报
  { path: '/v1/declaration/clearance/list', component: v1_declaration_clearance_list_OrderListPage, meta: { asideNavIndex: '/v1/declaration/clearance/list', asideNavOpeneds: ['/v1/declaration'] } },
  // 申报 - 商户订单申报 - 仓储申报
  { path: '/v1/declaration/warehouse/list', component: v1_declaration_warehouse_list_OrderListPage, meta: { asideNavIndex: '/v1/declaration/warehouse/list', asideNavOpeneds: ['/v1/declaration'] } },

  // 申报 - 推送管理 - 通知推送
  { path: '/v1/declaration/push/notice', component: v1_declaration_push_notice_NoticePushPage, meta: { asideNavIndex: '/v1/declaration/push/notice', asideNavOpeneds: ['/v1/declaration2'] } },

  // 申报 - 订单信息推送 - 运单处理
  { path: '/v1/declaration/logistics/waybill', component: v1_declaration_logistics_list_WaybillListPage, meta: { asideNavIndex: '/v1/declaration/logistics/waybill', asideNavOpeneds: ['/v1/declaration'] } },
  // 申报 - 订单信息推送 - 运单处理
  { path: '/v1/declaration-push/logistics/waybill', component: v1_declaration_logistics_list_WaybillListPage, meta: { asideNavIndex: '/v1/declaration-push/logistics/waybill', asideNavOpeneds: ['/v1/declaration-push'] } },

]
