
// 商品-跨境商品管理-商品列表
const v1_goods_goods_list_GoodsListPage = () => import(/* webpackChunkName: "goods" */ '@/pages/v1/goods/goods/list/GoodsListPage')
// 商品-跨境商品管理-商品编辑（添加/编辑）
const v1_goods_goods_edit_GoodsEditPage = () => import(/* webpackChunkName: "goods" */ '@/pages/v1/goods/goods/edit/GoodsEditPage')
// 商品-跨境商品管理-商品上传
const v1_goods_goods_upload_GoodsUploadPage = () => import(/* webpackChunkName: "goods" */ '@/pages/v1/goods/goods/upload/GoodsUploadPage')

// 商品 - 货品SKU列表
const v1_goods_goods_item_list_GoodsItemListPage = () => import(/* webpackChunkName: "goods" */ '@/pages/v1/goods/goods_item/list/GoodsItemListPage')
// 商品 - 货品SKU 编辑（添加/编辑）
const v1_goods_goods_item_edit_GoodsItemEditPage = () => import(/* webpackChunkName: "goods" */ '@/pages/v1/goods/goods_item/edit/GoodsItemEditPage')


// 商品 - 组合商品列表
const v1_goods_combine_goods_list_CombineGoodsListPage = () => import(/* webpackChunkName: "goods" */ '@/pages/v1/goods/combine_goods/list/CombineGoodsListPage')

// 商品 - 菜鸟仓货品库存
const v1_goods_cainiao_goods_quantity_list_CainiaoGoodsQuantityListPage = () => import(/* webpackChunkName: "goods" */ '@/pages/v1/goods/cainiao_goods_quantity/list/CainiaoGoodsQuantityListPage')


// -----------------------------------------------------------------------------------------------------


export const goodsRoute = [
  // 商品-跨境商品管理-商品列表
  { path: '/v1/goods/goods/list', component: v1_goods_goods_list_GoodsListPage, meta: { asideNavIndex: '/v1/goods/goods/list', asideNavOpeneds: ['/v1/goods'] } },
  // 商品-跨境商品管理-商品添加
  { path: '/v1/goods/goods/add', component: v1_goods_goods_edit_GoodsEditPage, meta: { asideNavIndex: '/v1/goods/goods/add', asideNavOpeneds: ['/v1/goods'] }, props: { pageMode: 'insert' } },
  // 商品-跨境商品管理-商品编辑
  { path: '/v1/goods/goods/edit/:id', component: v1_goods_goods_edit_GoodsEditPage, meta: { asideNavIndex: '/v1/goods/goods/list', asideNavOpeneds: ['/v1/goods'] }, props: { pageMode: 'update' } },
  // 商品-跨境商品管理-商品上传
  { path: '/v1/goods/goods/upload', component: v1_goods_goods_upload_GoodsUploadPage, meta: { asideNavIndex: '/v1/goods/goods/upload', asideNavOpeneds: ['/v1/goods'] } },

  // 商品 - 商品管理 - 货品列表（新）
  { path: '/v1/goods/goods-item/list', component: v1_goods_goods_item_list_GoodsItemListPage, meta: { asideNavIndex: '/v1/goods/goods-item/list', asideNavOpeneds: ['/v1/goods'] } },
  // 商品 - 商品管理 - 货品列表（新）
  { path: '/v1/goods/goods-item/add', component: v1_goods_goods_item_edit_GoodsItemEditPage, meta: { asideNavIndex: '/v1/goods/goods-item/add', asideNavOpeneds: ['/v1/goods'] }, props: { pageMode: 'insert' } },
  // 商品 - 商品管理 - 货品列表（新）
  { path: '/v1/goods/goods-item/edit/:id', component: v1_goods_goods_item_edit_GoodsItemEditPage, meta: { asideNavIndex: '/v1/goods/goods-item/list', asideNavOpeneds: ['/v1/goods'] }, props: { pageMode: 'update' } },

  // 商品 - 商品管理 - 组合商品列表
  { path: '/v1/goods/combine-goods/list', component: v1_goods_combine_goods_list_CombineGoodsListPage, meta: { asideNavIndex: '/v1/goods/combine-goods/list', asideNavOpeneds: ['/v1/goods'] } },

  // 商品 - 商品管理 - 菜鸟仓货品库存
  { path: '/v1/goods/cainiao-goods-quantity/list', component: v1_goods_cainiao_goods_quantity_list_CainiaoGoodsQuantityListPage, meta: { asideNavIndex: '/v1/goods/cainiao-goods-quantity/list', asideNavOpeneds: ['/v1/goods'] } },

]

 

