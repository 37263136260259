
// 系统 - UKEY - UKEY信息
const v1_system_ukey_info_UkeyInfoPage = () => import(/* webpackChunkName: "system" */ '@/pages/v1/system/ukey/info/UkeyInfoPage')

// 系统 - 图片管理 - 图片列表
const v1_system_image_list_ImageListPage = () => import(/* webpackChunkName: "system" */ '@/pages/v1/system/image/list/ImageListPage')


// -----------------------------------------------------------------------------------------------------


export const systemRoute = [
  // 系统 - UKEY - UKEY信息
  { path: '/v1/system/ukey/info', component: v1_system_ukey_info_UkeyInfoPage, meta: { asideNavIndex: '/v1/system/ukey/info', asideNavOpeneds: ['/v1/system'] }},

  // 系统 - 图片管理 - 图片列表
  { path: '/v1/system/image/list', component: v1_system_image_list_ImageListPage, meta: { asideNavIndex: '/v1/system/image/list', asideNavOpeneds: ['/v1/system'] } },
]