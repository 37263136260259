// import config from '@/config/index.js'

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

export default {
    formatDateTime(date, fmt) {
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        }
        for (let k in o) {
            let str = o[k] + '';
            if (new RegExp(`(${k})`).test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
            }
        }
        return fmt;
    }
}


/*
import Vue from 'vue'

//保留两位有效数字
Vue.filter("formatMoney",function(value){
	if(value == 'undefined' || value === '' || value == null) return 0;
    value = parseFloat(value);
    return value.toFixed(2);
});

//保留两位有效数字且大于0的数字返回+
Vue.filter("formatMoneyAdd",function(value){
    value = parseFloat(value);
    if(value>0){
    	return '+'+value.toFixed(2);

    }
    return value.toFixed(2);
});

//大于等于100 显示99+
Vue.filter("numHandle",function(num){
	if (parseInt(num) >= 100 ){
		num = "99+"
	}
	return num
});
//输出年月日
Vue.filter("toDate",function(number,is_hfs){
	var n = number*1000;
	var date = new Date(n);
	var Y = date.getFullYear();
	var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) ;
	var D = (date.getDate() < 10 ? "0" + (date.getDate()) : date.getDate());
	var H = (date.getHours() < 10 ? "0" + (date.getHours()) : date.getHours());
	var F = (date.getMinutes() < 10 ? "0" + (date.getMinutes()) : date.getMinutes());
	var S = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds());
	

	return (Y+'-'+M+'-'+D);

});

Vue.filter("toDatetime",function(number,is_hfs){
	var n = number*1000;
	var date = new Date(n);
	var Y = date.getFullYear();
	var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) ;
	var D = (date.getDate() < 10 ? "0" + (date.getDate()) : date.getDate());
	var H = (date.getHours() < 10 ? "0" + (date.getHours()) : date.getHours());
	var F = (date.getMinutes() < 10 ? "0" + (date.getMinutes()) : date.getMinutes());
	var S = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds());
	
	if (is_hfs==1){
		return (Y+'-'+M+'-'+D);
	}else if (is_hfs==2){
        return (Y+'.'+M+'.'+D);
    }
	return (Y+'-'+M+'-'+D+' '+H+':'+F+':'+S);
});

//年月日时分秒 转化为 年月日
Vue.filter('dataTime', function (time) {
	if(typeof time != 'undefined'){
		var time = time.substring(0,10);
	}
    return time;
});



//去除反斜杠
Vue.filter('addressformat', function (item) {
    if(typeof item == 'string'){
        item = item.replace(/[\\ \/=]/g, "");
    }
    return item;
});

//设置默认值
Vue.filter('setdefault', function (item, val) {
    if(typeof item == 'undefined' || item == '' || item == null){
        return val;
    }
    return item;
});

//把手机号处理加星
Vue.filter('mobileformat', function (item) {
	if(typeof item == 'string'){
    	return item.substring(0,3)+"****"+item.substring(8,11);
    }
    return item;
});
*/