import GlobalConfig from '@/config/index.js'

var StorageUtils = {
    set(key, value) {
        // try {
            window.localStorage.setItem(GlobalConfig.storagePrefix + key, JSON.stringify(value))
        // } catch (e) {
        // }
    },
    get(key, defaultValue = undefined) {
        try {
            let keyValue = window.localStorage.getItem(GlobalConfig.storagePrefix + key)
            return (JSON.parse(keyValue) || (defaultValue || undefined))
        } catch (e) {
            console.log(e)
        }
    },
    remove(key) {
        window.localStorage.removeItem(GlobalConfig.storagePrefix + key)
    }
}

export default StorageUtils