import { createRouter, createWebHistory  } from 'vue-router'
import AuthUtils from '@/utils/auth-utils.js';

// import { merchantRoute } from './merchant'
import { goodsRoute } from './goods'
import { fenxiaoRoute } from './fenxiao'
import { tradeRoute } from './trade'
import { declarationRoute } from './declaration'
import { financeRoute } from './finance'
import { systemRoute } from './system'



const test_Test2 = () => import(/* webpackChunkName: "test" */ '@/pages/test/Test2')
const test_Test1Page = () => import(/* webpackChunkName: "test" */ '@/pages/test/Test1Page')
// const test_Test2Page = () => import(/* webpackChunkName: "test" */ '@/pages/test/Test2Page')

// 登录页面
const v1_common_LoginPage = () => import(/* webpackChunkName: "common" */ '@/pages/v1/common/LoginPage')
// 店铺选择页面
const v1_common_ShopPickPage = () => import(/* webpackChunkName: "common" */ '@/pages/v1/common/ShopPickPage')
// 默认Dashborad
const v1_common_DashboardPage = () => import(/* webpackChunkName: "common" */ '@/pages/v1/common/DashboardPage')

const v1_common_IndexPage = () => import(/* webpackChunkName: "common" */ '@/pages/v1/common/IndexPage')


const routes = [
  {
    path: '/login',
    component: v1_common_LoginPage
  },
  {
    path: '/v1/shop-picker',
    component: v1_common_ShopPickPage
  },
  { 
    path: '/',
    component: v1_common_DashboardPage,
    children: [
      // 商户
      // merchantRoute,
      // 商品
      ...goodsRoute,
      // 分销
      ...fenxiaoRoute,
      // 订单
      ...tradeRoute,
      // 申报
      ...declarationRoute,
      // 资金
      ...financeRoute,
      // 系统
      ...systemRoute,

      // 默认首页
      {path: '', component: v1_common_IndexPage},
    ],
  },

  
  
  { path: '/test1', component: test_Test2 },
]

// console.log(routes);

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})


const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  // console.log(to); console.log(from);
  if (AuthUtils.isValidLogin()) {
    if (to.path == '/v1/shop-picker') {
      next()
    } else {
      if (AuthUtils.checkShopId()) {
        // console.log(to); console.log(from);
        var r = from.query.r
        if (r != undefined && from.path == '/login') {
          var rPath = decodeURIComponent(r)
          if (to.path == rPath) {
            next()
          } else {
            next({ path: decodeURIComponent(r) })
          }
        } else {
          next()
        }
      } else {
        next({ path: '/v1/shop-picker' })
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/login', query: { r: encodeURIComponent(to.fullPath) } })
    }
  }
  // next()
})

router.afterEach(() => {
})


export default router