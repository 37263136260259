
// 分销 - 分销信息 - 分销合作关系
const v1_fenxiao_fenxiao_relation_list_FenxiaoRelationListPage = () => import(/* webpackChunkName: "fenxiao" */ '@/pages/v1/fenxiao/fenxiao_relation/list/FenxiaoRelationListPage')


// 分销 - 分销货品 - 分销货品列表
const v1_fenxiao_goods_mark_GoodsMarketPage = () => import(/* webpackChunkName: "fenxiao" */ '@/pages/v1/fenxiao/goods/market/GoodsMarketPage')


// 分销 - 分销商 - 供货关系管理
const v1_fenxiao_purchase_goods_item_GoodsItemListPage = () => import(/* webpackChunkName: "system" */ '@/pages/v1/fenxiao/purchase/goods_item/GoodsItemListPage')
// 分销 - 分销商 - 分销采购表
const v1_fenxiao_purchase_list_PurchaseListPage = () => import(/* webpackChunkName: "system" */ '@/pages/v1/fenxiao/purchase/list/PurchaseListPage')



// -----------------------------------------------------------------------------------------------------


export const fenxiaoRoute = [

  // 分销 - 分销信息 - 分销合作关系
  { path: '/v1/fenxiao/fenxiao-relation/list', component: v1_fenxiao_fenxiao_relation_list_FenxiaoRelationListPage, meta: { asideNavIndex: '/v1/fenxiao/fenxiao-relation/list', asideNavOpeneds: ['/v1/fenxiao'] } },

  // 分销 - 分销商 - 供货关系管理
  { path: '/v1/fenxiao/purchase/goods-item', component: v1_fenxiao_purchase_goods_item_GoodsItemListPage, meta: { asideNavIndex: '/v1/fenxiao/purchase/goods-item', asideNavOpeneds: ['/v1/fenxiao3'] } },
  // 分销 - 分销货品 - 分销货品列表
  { path: '/v1/fenxiao/goods/market', component: v1_fenxiao_goods_mark_GoodsMarketPage, meta: { asideNavIndex: '/v1/fenxiao/goods/market', asideNavOpeneds: ['/v1/fenxiao'] } },


  // 分销 - 分销信息 - 分销采购表
  { path: '/v1/fenxiao/purchase/list', component: v1_fenxiao_purchase_list_PurchaseListPage, meta: { asideNavIndex: '/v1/fenxiao/purchase/list', asideNavOpeneds: ['/v1/fenxiao3'] } },


]