

// 账单 - 账户管理 - 账户信息
const v1_finance_account_list_AccountListPage = () => import(/* webpackChunkName: "merchant" */ '@/pages/v1/finance/account/list/AccountListPage')


// ================================================================================================


export const financeRoute = [

  // 账单 - 账户管理 - 账户信息
  { path: '/v1/finance/account/list', component: v1_finance_account_list_AccountListPage, meta: { asideNavIndex: '/v1/finance/account/list', asideNavOpeneds: ['/v1/finance1'] } },

]