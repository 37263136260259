import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import App from './App.vue'



// import '@/assets/css/common.css'


const app = createApp(App)
app.use(ElementPlus, { locale: zhCn, zIndex: 3000 }) //  size: 'small', 

import router from './router'
app.use(router)

// import { Edit } from '@element-plus/icons-vue'
// app.use(Edit)

import store from './store'
app.use(store)


import { registApp } from '@/register.js'
registApp(app)

// import * as ElIcons from '@element-plus/icons-vue'
// for (let iconName in ElIcons) {
//   app.component(iconName, ElIcons[iconName])
// }

app.mount('#app')
// createApp(App).mount('#app')

